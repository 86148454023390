@import "global";

.wagestream {

  &__letter {
    max-width: 630px;
    left: -150px;
    top: -60px;
  }

  .citrus {
    top: 15px;
    right: -160px;
    background-color: rgba(#279187,0.1);
  }

  .section__cta {
    background-color: #a88afa;

    &:hover {
      background-color: darken(#a88afa, 5);
    }

    .arrow path {
      fill: #ffffff;
    }

    &:after {
      background-color: #a88afa;
    }

  }

}